/* You can add global styles to this file, and also import other style files */
@import "app/styles/variables.scss";

html,
body {
  min-height: 100%;
  height: 100%;
  background-color: #f1f1f9;
  overflow-x: hidden;
  overflow-y: hidden;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

#clear-style {
  padding: 0 !important;
  background-color: transparent !important;
}

button:disabled#clear-style mat-icon {
  cursor: auto !important;
  color: #00000080;
}

button:disabled mat-icon {
  cursor: auto !important;
  color: #00000080;
}

.custom-modalbox {
  mat-dialog-container {
    padding: 0;
  }
}

.main-navigation .mat-tab-body-wrapper {
  flex-grow: 1;
  .mat-tab-body-content {
    display: flex;
    app-test-runs-tab {
      display: flex;
      width: 100%;
      & > app-main-list,
      & > app-test-cases-creation-mode {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    }
  }
}

.mat-list-base .main-list__add-new-item.mat-list-item {
  height: auto;
  .mat-form-field {
    width: 100%;
  }
}

.mdc-list {
  padding: 0 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: none !important;
  padding: 0 !important;
}

.mat-mdc-form-field-focus-overlay {
  background: none !important;
}

.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  border-color: #2d64ba !important;
  background-color: #2d64ba !important;
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: #2d64ba !important;
  background-color: #2d64ba !important;
}

.mdc-dialog__title {
  padding: 0 !important;
  &::before {
    height: auto !important;
  }
}

.card .list-card .list-card__item.creation-mode .mat-pseudo-checkbox {
  display: block;
}